.SpindelGraph-header {
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.SpindelChartBackground {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  color: white;
  margin-bottom: 50px;
  height: 400px;
}

.SpindelChartBackground-Scroll {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 50px;
  height: 450px;
}

.EntireComponent {
  color: #282c34;
}

.graph-buttons {
  padding-left: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
